.logout_link h4{
    color: white!important;
}
.logout_link h4:hover{
    color: #f4442c!important;
    cursor: pointer;
}
  /* For devices with a maximum width of 414px (typical smartphone width) */
  @media only screen and (max-width: 414px) {
    /* Your CSS rules for the element you want to display on mobile screens */
    .my-hamburger {
      display: block; /* Or any other property to make the element visible */
    }

    .my-middle-header{
 
      padding-bottom: 0 !important;
  
     
    }
  }
  /* Optional: hide the element on screens larger than 414px */
  @media only screen and (min-width: 991px) {
    .my-hamburger {
      display: none; /* Or any other property to hide the element */
    }
  }

  .my-hamburger:before{
    size: 2rem;
    color: "#fff"
  }

  .w-icon-hamburger:before{color: white; font-size: 2rem;}