.consentpopup {
  position: fixed;
  bottom: 0px;
  padding: 30px 5%;
  background-color: #183544;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.consentpopup h4 {
  color: white;
}

.consentpopup p {
  /* line-height: 1; */
}

.consentpopup__button__accept {
  background-color: #f4442c;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  transition: background-color 500ms;
}

.consentpopup__button__accept:hover {
  background-color: #8d2517;
}

.consentpopup__button__reject {
  background-color: #142936;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  transition: background-color 500ms;
}

.consentpopup__button__reject:hover{
  background-color: #0f1f29;
}