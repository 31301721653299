.countdown {
    font-size: 11px !important;
    font-family: Poppins;
    text-align: center;
    color: white!important;
  }
  
  .countdown section {
    display: flex;
    justify-content: center;
    align-content: center;
    color: white!important;
  }
  
  .countdown section .wrapper {
    
    color: white!important;
  }
  
  .countdown section .wrapper h2 {
    font-size: 12px;
    margin: 5px 5px;
    color: white!important;
  }
  
  .countdown section .wrapper h6 {
    margin: 0;
    color: white!important;
  }
  