.leaflet-container {
  z-index: 0;
  width: 100%;
  height: 100%;
}

.category-dropdown > a:after {
  display: none !important;
}

.dropdown > a:after {
  display: none !important;
}

#gridview {
  display: none;
}

@media only screen and (max-width: 780px) {
  #gridview {
    display: block; /* or any other value you prefer */
    width: 100%;
  }

  #listview {
    display: none;
  }
}

@media only screen and (min-width: 990px) {
  #searchfield {
    display: none;
  }
}


.my-first-icon {
  margin-left: 2.5rem;
}

.my-last-icon {
  margin-right: 2.5rem;
}
#lurch_ad {
  display: none;
}
@media only screen and (max-width: 400px) {
  .newsletter-heading-box {
    display: flex;
    justify-content: center;
  }
  .newsletter-button-box {
    display: flex;
    justify-content: center;
  }


  .input-wrapper-inline,
  .newsletter-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .my-first-icon {
    margin-left: 0;
  }

  .my-last-icon {
    margin-right: 0;
  }

  #shopbanner {
    display: none !important;
  }
}

@media only screen and (max-width: 370px) {
  .newsletter-text {
    padding-left: 30px;
    padding-right: 40px;
    
  }
  .input-wrapper-inline .btn {
  
    margin-right: 30px;
   
}
}

input[type="checkbox"]:checked {
  background-color: #f4442c !important; /* Change this to the desired color */
}

.widget-collapsible .toggle-btn:after,
.widget-collapsible .toggle-btn:before,
.leaflet-attribution-flag {
  display: none !important;
}

.product-single .product-size-swatch .size:hover {
  color: #666;
  border-color: #ccc;
}

.sidebar-content {
  margin-top: 0px !important;
}

.post-content p {
  -webkit-line-clamp: 10;
}

.termsfeed-com---nb-interstitial-overlay{
  width: 100vw !important;
  height: 100vh !important;
  max-height: 5000px;
}

img.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
  background-color: transparent;
  border: none;
}

@media only screen and (max-width: 430px) {
  .icon-box-side .icon-box-content {
    text-align: start !important;
  }
}

@media only screen and (max-width: 990px) {
  #lurch_ad {
    display: block;
  }
}

html {
  scroll-behavior: smooth;
}

.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-dark .cc-nb-reject {
  color: white !important;
  background-color: #f4442c !important;
  border: none !important;
}

#open_preferences_center {
  display: none;
}

.termsfeed-com---palette-dark .cc-nb-okagree:focus {
  box-shadow: none !important;
}
