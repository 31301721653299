/* MobileSidebar.css */
.mobile-sidebar {
  position: fixed;
  top: 0;
  left: -80%;
  width: 80%;
  height: 100%;
  background-color: #222;
  transition: left 0.3s;
}

.mobile-sidebar.open {
  left: 0;
}

.toggle-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999999999999999999999999999999;
}

.sidebar-content {
  margin-top: 50px;
  z-index: 1000;
}

/* Media Query for Mobile Devices */
@media (min-width: 768px) {
  .mobile-sidebar {
    display: none;
  }
}

.checkboxContainer {
  display: flex;
  padding: 1rem 0 1rem 0.2rem;
}

.myCheckbox {
  margin-right: 1rem;
  width: 18px;
  height: 18px;
  line-height: 1.3rem;
  color: #999;
  border-radius: 2px;
  accent-color: #F4442C;
}

.checkBoxLink {
  color: #333;
  font-family: Poppins;
  font-size: 14px;

}

.checkBoxLink:hover {
  color: #333;

}
